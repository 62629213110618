import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby'

function GoogleReviews(data) 
{
  const [rand, setRand] = useState(0);
  useEffect(() => {
    //setRand(Math.floor(Math.random() * 4) + 1);
    setRand(Math.floor(Math.random() * 1) + 1);
  },[]);

  let ratings = []
  for (let i = 0; i < 5; ++i) {
    if (data.reviewItems.edges[rand].node.rating > i) {
      ratings.push(<span className="text-lg text-yellow" key={i}>&#9733;</span>)
    } else {
      ratings.push(<span className="text-lg text-grey-9" key={i}>&#9733;</span>)
    }
  }

  let excerpt = data.reviewItems.edges[rand].node.text.length > 100 ? data.reviewItems.edges[rand].node.text.substring(0, 100) + "..." : data.reviewItems.edges[rand].node.text;
  
  return (
    <div>
      <p className="text-grey-4 text-2xl mb-3">&#8220;{excerpt}&#8221;</p>
      <p className="text-lg">
      <span className="text-google-blue font-bold capitalize">
      &mdash; {data.reviewItems.edges[rand].node.author_name}</span> <small className="text-grey-9">({data.reviewItems.edges[rand].node.relative_time_description})</small> <img className="inline-block" width="20" height="20" src="/images/google_sm.png" alt="Google" /></p>
      <div className="space-x-3 inline-block mt-1">
        {ratings}
      </div>
    </div>
    
  )
}

export default () => (
  <StaticQuery
    query={graphql`
    {
      googleReviewsTotalRatings {
        total_rating
        total_reviews
      }
      allGoogleReviews {
        edges {
          node {
            author_name
            author_url
            relative_time_description
            rating
            text
            location
            time
          }
        }
      }
    }
  `} 
  render = { data => (
    <>
      <div className="w-full relative bg-grey-e py-12">
        <div className="absolute -inset-0 bg-stardust" />
        <div className="w-full lg:w-9/12 mx-auto max-w-screen-2xl relative font-verdana">
          <div className="flex-none sm:flex gap-12 justify-between items-center text-center">
            <div className="w-full lg:w-4/12 bg-white py-6 px-6">
              <div>
                <span className="text-black text-3xl font-bold leading-snug mb-1 block">Google Rating</span>
                <span className="text-yellow text-3xl font-bold leading-snug mb-1 block flex justify-center items-center space-x-3">
                  <span>{data.googleReviewsTotalRatings.total_rating === 5 ? '5.0' : data.googleReviewsTotalRatings.total_rating}</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span>
                </span>
                <span className="text-grey-9 text-sm leading-snug mb-3 block">based on {data.googleReviewsTotalRatings.total_reviews} reviews</span>
              </div>
              <div>
                <a href="https://search.google.com/local/reviews?placeid=ChIJdfSPqXo7DW0RtHp-hMmxw44" target="_blank" className="text-green text-base font-bold border-b-2 border-green" title="More google reviews" rel="noopener nofollow">More reviews</a>
              </div>
            </div>
            <div className="w-full lg:w-8/12 pt-12 pb-6 sm:pt-0 sm:pb-0 px-6 sm:px-6">
              <div className="max-w-none text-center">
                <GoogleReviews reviewItems={data.allGoogleReviews} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )}
  />
)